import React from "react";
import ReactHtmlParser from "react-html-parser";
import Layout from "./layout";

const TitleContentPage = ({ title, content }) => {
  return (
    <Layout>
      <section className="container mt-3">
        <div className="columns">
          <div className="column is-12">
            <h3 className="title is-3">{title}</h3>
            <div className="card">
              <div className="card-content">
                <div className="content">{ReactHtmlParser(content)}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TitleContentPage;
